import {ReglementRepository} from "@/repository/reglement";

export const ClientStore = {
    namespaced: true,
    state() {
        return {
            clients: [],
            loading_status: "INITIAL",
        };
    },
    mutations: {
        setClients(state, clients) {
            state.clients = clients;
        },
        setStatusLoading(state) {
            state.loading_status = "LOADING";
        },
        setStatusLoaded(state) {
            state.loading_status = "LOADED";
        },
        setStatusError(state) {
            state.loading_status = "ERROR";
        }
    },
    getters: {
        clients(state) {
            return state.clients;
        },
        fetch_status(state) {
            return state.loading_status;
        }
    },
    actions: {
        async fetchClients(context) {
            try {
                context.commit("setStatusLoading");
                var repo = new ReglementRepository();
                var clients = await repo.fetchClients();
                context.commit("setClients", clients);
                context.commit("setStatusLoaded");
                return clients;
            } catch (e) {
                context.commit("setStatusError");
                context.dispatch("annonce/annonceSuccess", "Une erreur est survenue", {root: true});
            }
        },
    }
}