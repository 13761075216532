import axios from "axios";

export const GroupsStore = {
    namespaced: true,
    state() {
        return {};
    },
    mutations: {},
    getters: {},
    actions: {
        async fetchGroups(context) {
            try {
                let response = await axios.get(`/api/groups/`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                    context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async generateDefaultGroups(context) {
            try {
                await axios.post(`/api/groups/generate-groups/`, null, context.rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
                return "failure";
            }
        },


    }
}