import axios from "axios";

export const DossierStatutStore = {
    namespaced: true,
    state() {
        return {
            statuts: [],
            statut_fetch_status: "INITIAL",
        }
    },
    mutations: {
        setStatuts(state, statuts) {
            state.statuts = statuts;
        },
        setStatutsLoading(state) {
            state.statut_fetch_status = "LOADING";
        },
        setStatutsLoaded(state) {
            state.statut_fetch_status = "LOADED";
        },
        setStatutsError(state) {
            state.statut_fetch_status = "ERROR";
        },
    },
    getters: {
        statut_fetch_status(state) {
            return state.statut_fetch_status;
        },
        statuts(state) {
            return state.statuts;
        }
    },
    actions: {
        async getStatuts({commit, dispatch, rootGetters}) {
            try {
                commit("setStatutsLoading");
                let response = await axios.get("/api/statuts/", rootGetters["auth/getAuth"]);
                commit("setStatutsLoaded");
                commit("setStatuts", response.data);
                return response.data;
            } catch (e) {
                commit("setStatutsError");
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
                return [];
            }
        },
        async postStatut({dispatch, rootGetters}, statut) {
            try {
                let response = await axios.post("/api/statuts/", statut, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async patchStatut({dispatch, rootGetters}, statut) {
            try {
                let response = await axios.patch("/api/statuts/" + statut.id + "/", statut, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async deleteStatut({dispatch, rootGetters}, statut) {
            try {
                await axios.delete("/api/statuts/" + statut.id + "/", rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
                return "failure";
            }
        },
    }
}