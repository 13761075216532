import config from "@/config";
import axios from "axios";

export const PasswordResetStore = {
    namespaced: true,
    state:() => ({}),
    mutations: {},
    actions: {
        async sendResetEmail({dispatch, rootGetters}, email) {
            let axiosConfig = rootGetters["auth/getAuth"];
            const url = config.BASE_URL + "/api/password_reset/";
            try {
                let response = await axios.post(url, {email: email}, axiosConfig);
                return response.status === 200 ? "success" : "failure";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
        async validateToken({dispatch, rootGetters}, token) {
            let axiosConfig = rootGetters["auth/getAuth"];
            const url = config.BASE_URL + "/api/password_reset/validate_token/";
            try {
                let response = await axios.post(url, {token}, axiosConfig);
                return response.status === 200 ? "success" : "failure";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
        async confirmPasswordReset({dispatch, rootGetters}, payload) {
            let axiosConfig = rootGetters["auth/getAuth"];
            const url = config.BASE_URL + "/api/password_reset/confirm/";
            try {
                let response = await axios.post(url, {token: payload.token, password: payload.password}, axiosConfig);
                return response.status === 200 ? "success" : "failure";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        }
    }
}