import axios from "axios";

export const BienImmobilierStore = {
    namespaced: true,
    state: {

    },
    actions: {
        async patchBienImmobilier({rootGetters, dispatch}, bien_immobilier) {
            try {
                let response = await axios.patch(`/api/bien_immobilier/${bien_immobilier.id}/`, bien_immobilier, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postBienImmobilier({rootGetters, dispatch}, bien_immobilier) {
            try {
                let response = await axios.post(`/api/bien_immobilier/`, bien_immobilier, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root:true});
                return null;
            }
        },
        deleteBienImmobilier({rootGetters, dispatch}, bien_immobilier) {
            try {
                axios.delete(`/api/bien_immobilier/${bien_immobilier.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root:true});
            }
        }

    }
};