import axios from "axios";

export const UserStore = {
    namespaced: true,
    state() {
        return {
            users: [],
            loading_status: "INITIAL",
        };
    },
    mutations: {
        setUsers(state, users) {
            state.users = users;
        },
        setStatusLoading(state) {
            state.loading_status = "LOADING";
        },
        setStatusLoaded(state) {
            state.loading_status = "LOADED";
        },
        setStatusError(state) {
            state.loading_status = "ERROR";
        }
    },
    getters: {
        users(state) {
            return state.users;
        },
        instructeurs(state) {
            return state.users.filter((user) => user.group === "Instructeur" || user.group === "Administrateur");
        },
        enqueteurs(state) {
            return state.users.filter((user) => user.group === "Enquêteur" || user.group === "Administrateur");
        },
        maitres_oeuvre(state) {
            return state.users.filter((user) => user.group === "Maître d'oeuvre");
        },
        conducteur_travaux(state) {
            return state.users.filter((user) => user.group === "Conducteur de travaux");
        },
        loading_status(state) {
            return state.loading_status;
        }
    },
    actions: {
        async fetchUsers(context) {
            try {
                context.commit("setStatusLoading");
                let response = await axios.get(`/api/users/`, context.rootGetters["auth/getAuth"]);
                context.commit("setUsers", response.data);
                context.commit("setStatusLoaded");
                return response.data;
            } catch (e) {
                context.commit("setStatusError");
                context.dispatch("annonce/annonceSuccess", "Une erreur est survenue", {root: true});
            }
        },
        async putUser(context, user) {
            try {
                let response = await axios.patch(`/api/users/${user.id}/`, user, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceSuccess", "Une erreur est survenue", {root: true});
            }
        },
        async postUser(context, user) {
            try {
                let response = await axios.post( `/api/users/`, user, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceSuccess", "Une erreur est survenue", {root: true});
            }
        },
    }
}