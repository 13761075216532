import axios from "axios";
import * as config from "@/config.json";

export const FinancementStore = {
    namespaced: true,
    state: {

    },
    actions: {
        async patchFinancement({rootGetters, dispatch}, financement) {
            try {
                let response = await axios.patch(`/api/financement/${financement.id}/`, financement, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postFinancement({rootGetters, dispatch}, financement) {
            try {
                let response = await axios.post(`/api/financement/`, financement, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async deleteFinancement({rootGetters, dispatch}, financement) {
            try {
                await axios.delete(`/api/financement/${financement.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async fetchFinancementsByDossierId({rootGetters, dispatch}, dossier_id) {
            try {
                let response = await axios.get(`/api/financement/?dossier=${dossier_id}`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e,{root:true})
                return undefined;
            }
        },
        async validateParameters({rootGetters, dispatch}, payload) {
            try {
                let response = await axios.post(`/api/financement/validate-parameters/`, payload, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async generatePlan({rootGetters, dispatch}, payload) {
             try {
                let response = await axios.post(`/api/financement/generate/`, payload, rootGetters["auth/getAuth"]);
                let url = config.BASE_URL + response.data["url"];
                const link = document.createElement('a');
                link.target = "_blank";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
            catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        }
    }
};