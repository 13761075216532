import axios from "axios";
import {AUTHENTIFICATION_STATUS} from "@/constants/AuthentificationStatus";



export const AuthStore = {
    namespaced: true,
    state() {
        return {
            logged_user: null,
            status: AUTHENTIFICATION_STATUS.UNAUTHENTICATED,
            auth: null,
        }
    },
    mutations: {
        setStatus(state, value) {
            if (value in AUTHENTIFICATION_STATUS) {
                state.status = value;
            } else {
                throw Error("Not a valid authenfication status");
            }
        },
        setLoggedUser(state, value) {
            state.logged_user = value;
        },
        setAuth(state, value) {
            state.auth = value;
        }
    },
    getters: {
        getStatus(state) {
            return state.status;
        },
        getLoggedUser(state) {
            return state.logged_user;
        },
        getAuth(state) {
            return state.auth;
        }
    },
    actions: {
        async login(context, {username, password, rememberMe}) {
            let auth = {auth: {username, password}}
            try {
                let response = await axios.get("/api/users/me", auth);
                context.commit("setLoggedUser", response.data);
                context.commit("setStatus", AUTHENTIFICATION_STATUS.LOGGED_IN);
                context.commit("setAuth", auth);
                context.dispatch("saveToSession", {username, password});
                if (rememberMe) context.dispatch("saveToLocal", {username, password});
            } catch (e) {
                if (e.response.status === 403) {
                    context.commit("setStatus", AUTHENTIFICATION_STATUS.REFUSED);
                } else {
                    context.commit("setStatus", AUTHENTIFICATION_STATUS.ERROR);
                }
            }
        },
        async refresh(context) {
            try {
                let response = await axios.get("/api/users/me", context.getters["getAuth"]);
                context.commit("setLoggedUser", response.data);
                return "success";
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        },
        async saveToSession(context, {username, password}) {
            sessionStorage.setItem("username", username);
            sessionStorage.setItem("password", password);
        },

        async saveToLocal(context, {username, password}) {
            localStorage.setItem("username", username);
            localStorage.setItem("password", password);
        },
        isLogged: async function (context, storage) {
            let username = storage.getItem("username");
            let password = storage.getItem("password");
            if (username === null || password === null) {
                return false;
            }
            let auth = {auth: {username, password}}
            try {
                let response = await axios.get("/api/users/me", auth);
                context.commit("setLoggedUser", response.data);
                context.commit("setAuth", auth);
                context.commit("setStatus", AUTHENTIFICATION_STATUS.LOGGED_IN);
                return true;
            } catch (e) {
                return false;
            }
        },
        async isSessionLogged(context) {
            return await context.dispatch("isLogged", sessionStorage);
        },

        async isStorageLogged(context) {
            return await context.dispatch("isLogged", localStorage);
        },

        async logout(context) {
            context.commit("setAuth", null);
            context.commit("setLoggedUser", null);
            localStorage.clear();
            sessionStorage.clear();
            context.commit("setStatus", AUTHENTIFICATION_STATUS.UNAUTHENTICATED);
        }
    }

}