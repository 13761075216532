import axios from "axios";

export const HonoraireStore = {
    namespaced: true,
    state: {

    },
    actions: {
        async patchHonoraire({rootGetters, dispatch}, honoraire) {
            try {
                let response = await axios.patch(`/api/honoraires/${honoraire.id}/`, honoraire, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postHonoraire({rootGetters, dispatch}, honoraire) {
            try {
                let response = await axios.post(`/api/honoraires/`, honoraire, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async fetchHonorairesByDossierId({rootGetters, dispatch}, dossier_id) {
            try {
                let response = await axios.get(`/api/honoraires/?dossier=${dossier_id}`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e,{root:true})
                return undefined;
            }
        },
        async deleteHonoraire({rootGetters, dispatch}, honoraire) {
            try {
                await axios.delete(`/api/honoraires/${honoraire.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        }

    }
};