import axios from "axios";

export const ModelDocStore = {
    namespaced: true,
    state() {
        return {
            modeldocs: [],
        };
    },
    mutations: {
        setModelDocs(state, modeldocs) {
            state.modeldocs = modeldocs;
        }
    },
    getters: {
        getModelDocs(state) {
            return state.modeldocs;
        }
    },
    actions: {
        async patchModelDoc({rootGetters, dispatch}, modeldoc) {
            try {
                let response = await axios.patch(`/api/modeldocs/${modeldoc.id}/`, modeldoc, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postModelDoc({rootGetters, dispatch}, modeldoc) {
            try {
                let response = await axios.post(`/api/modeldocs/`, modeldoc, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async deleteModelDoc({rootGetters, dispatch}, modeldoc) {
            try {
                await axios.delete(`/api/modeldocs/${modeldoc.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                console.log(e);
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async fetchModelDocs({rootGetters, commit, dispatch}) {
            try {
                let response = await axios.get(`/api/modeldocs/`, rootGetters["auth/getAuth"]);
                commit("setModelDocs", response.data);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async getDossierUnfilledFields({rootGetters, dispatch}, payload) {
            try {
                let response = await axios.get(`/api/modeldocs/${payload.modelDocId}/unfilled/?dossier=${payload.dossierId}`, rootGetters["auth/getAuth"]);
                return response.data["data"];
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return [];
            }
        },

    }
};