import axios from "axios";

export const StatsStore = {
    namespaced: true,
    state() {
        return {}
    },
    actions: {
        async fetchIndicateursDEAL(context, year) {
            try {
                let response = await axios.get(`/api/stats/indicateurs_deal/?year=${year}`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchFinancementsPercus(context, year) {
            try {
                let response = await axios.get(`/api/stats/financements_percus/?year=${year}`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchFinancementsEngages(context, year) {
            try {
                let response = await axios.get(`/api/stats/financements_engages/?year=${year}`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchDossierParEtat(context, year) {
            try {
                let response = await axios.get(`/api/stats/dossier_par_etat/?year=${year}`, context.rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                context.dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        }
    }
}