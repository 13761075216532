import axios from "axios";

export const BeneficiaireStore = {
    namespaced: true,
    state: {

    },
    actions: {
        async patchBeneficiaire({rootGetters, dispatch}, beneficiaire) {
            try {
                let response = await axios.patch(`/api/beneficiaires/${beneficiaire.id}/`, beneficiaire, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postBeneficiaire({rootGetters, dispatch}, beneficiaire) {
            try {
                let response = await axios.post(`/api/beneficiaires/`, beneficiaire, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root:true});
                return null
            }
        },
        deleteBeneficiaire({rootGetters, dispatch}, beneficiaire) {
            try {
                axios.delete(`/api/beneficiaires/${beneficiaire.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        }

    }
};