<template>
  <v-list>
    <NavigationLink v-for="(link, index) in links" :key="index" :link="link"/>
  </v-list>
</template>
<script>
import {getLinks} from "@/router/routes";
import NavigationLink from "@/components/home/NavigationLink";
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'NavigationBar',
  components: {NavigationLink},
  mixins: [AuthMixin],
  date() {
    return {

    }
  },
  computed: {
    links() {
      let links = getLinks();
      return links.filter(link => this.logged_user === null ? false : this.isIntern ? true : link.intern === false);
    }
  }
}
</script>