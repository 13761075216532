import axios from "axios";
import * as config from '@/config.json';

export const DocumentStore = {
    namespaced: true,
    state() {
        return {
            documents: [],
        };
    },
    mutations: {
        setDocuments(state, documents) {
            state.documents = documents;
        }
    },
    getters: {
        getDocuments(state) {
            return state.documents;
        }
    },
    actions: {
        async patchDocument({rootGetters, dispatch}, document) {
            try {
                let response = await axios.patch(`/api/documents/${document.id}/`, document, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async patchDocumentWithFile({rootGetters, dispatch}, document) {
            try {
                let data = new FormData();
                for(const key in document) {
                    if(document[key] !== null) data.append(key, document[key]);
                }
                let response = await axios.patch(`/api/documents/${document.id}/`, data, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postDocument({rootGetters, dispatch}, document) {
            try {
                let response = await axios.post(`/api/documents/`, document, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root:true});
            }
        },
        async deleteDocument({rootGetters, dispatch}, document) {
            try {
                await axios.delete(`/api/documents/${document.id}/`, rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchDocuments({rootGetters, commit, dispatch}) {
            try {
                let response = await axios.get(`/api/documents/`, rootGetters["auth/getAuth"]);
                commit("setDocuments", response.data);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async fetchDocumentsByDossierId({rootGetters, dispatch}, dossier_id) {
            try {
                let response = await axios.get(`/api/documents/?dossier=${dossier_id}`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async downloadAsPdf({rootGetters, dispatch}, documentModel) {
            try {
                let response = await axios.post(`/api/documents/${documentModel.id}/download_pdf/`, {}, rootGetters["auth/getAuth"]);

                let url = config.BASE_URL + response.data["url"];
                const link = document.createElement('a');
                link.target = "_blank";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
            catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async startDocumentSigning({rootGetters, dispatch}, document) {
            try {
                let response = await axios.post(`/api/documents/${document.id}/sign/`, {}, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root:true});
            }
        },
        async signDirectly({rootGetters, dispatch}, payload) {
            try {
                let data = {signatures: payload.signatures};
                let response = await axios.post(`/api/documents/${payload.document}/sign_directly/`, data, rootGetters["auth/getAuth"]);
                let url = config.BASE_URL + response.data["url"];
                const link = document.createElement('a');
                link.target = "_blank";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        }
    }
};