import {ConfigRepository} from "@/repository/config";

export const ConfigStore = {
    namespaced: true,
    state() {
        return {
            configs: [],
        };
    },
    mutations: {
        setConfig(state, configs) {
            state.configs = configs;
        },
        addConfig(state, config) {
            state.configs.push(config);
            state.configs = state.configs.sort((a, b) => a.id - b.id);
        },

        removeConfig(state, config) {
            state.configs = state.configs.filter((t) => t.id !== config.id);
        }
    },
    getters: {
        objectifs(state) {
            return state.configs.filter((item) => item.key.includes("GOAL_"));
        },
        configs(state) {
            return state.configs;
        }
    },
    actions: {
        async fetchConfigs(context) {
            const configRepository = new ConfigRepository();
            let configs = await configRepository.fetch();
            context.commit("setConfig", configs);
        },
        async postConfig(context, payload) {
            const configRepository = new ConfigRepository();
            let config = await configRepository.post(payload);
            context.commit("addConfig", config);
        },
        async deleteConfig(context, payload) {
            const configRepository = new ConfigRepository();
            await configRepository.delete(payload);
            context.commit("removeConfig", payload);
        }
    }
}