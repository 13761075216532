export const DOSSIER_FETCH_STATUS = {
    initial: "initial",
    loading: "loading",
    loaded: "loaded",
    error: "error"
}

export const DOSSIER_ETATS = {
    "incomplete": "Incomplet",
    "prospect": "Dossier prospect",
    "previsite": "En pré-visite",
    "contrevisite": "Contre-Visite",
    "technique": "Dossier technique en cours",
    "financement": "Plan de financement en cours",
    "attenteaccord": "ATTENTE ACCORD ATTRIBUTAIRES / FINANCEURS / AUTRES",
    "visitepriori": "VISITES A PRIORI",
    "depotschoelcher": "EN ATTENTE DEPOT SCHOELCHER",
    "depotschoelcherarrete": "DEPOT SCHOELCHERARRETE",
    "accorddef": "ATTENTE ACCORD DEFINITIF  MARTINIQUE HABITAT",
    "travauxaprogrammer": "TRAVAUX A PROGRAMMER - ATTENTE PAIEMENT AP ET/OU OFFRE DE PRET",
    "travauxinities": "TRAVAUX INITIES OU PROGRAMMÉS",
    "visiteposteriori": "VISITE A POSTERIORI",
    "visiteposterioriok": "VISITE A POSTERORI OK - ATTENTE REGLEMENT SUBVENTION",
    "abandondeal": "ABANDON (après DEAL)",
    "abandonetude": "ABANDON (après étude administrative ou prèvisite)",
    "abandontechnique": "ABANDON (après visite technique)",
}