import {SelectRepository} from "@/repository/select";

export const SelectStore = {
    namespaced: true,
    state() {
        return {
            selects: [],
            fetch_status: "INITIAL",
        };
    },
    mutations: {
        setSelect(state, selects) {
            state.selects = selects;
        },
        setStatutsLoading(state) {
            state.fetch_status = "LOADING";
        },
        setStatutsLoaded(state) {
            state.fetch_status = "LOADED";
        },
        setStatutsError(state) {
            state.fetch_status = "ERROR";
        },
    },
    getters: {
        statuts_matrimoniaux(state) {
            return state.selects.filter((item) => item.field === 'situation_matrimoniale');
        },
        types_biens(state) {
            return state.selects.filter((item) => item.field === 'type_bien');
        },
        types_assainissement(state) {
            return state.selects.filter((item) => item.field === 'type_assainissement');
        },
        nombre_etage(state) {
            return state.selects.filter((item) => item.field === 'nbre_etage');
        },
        titres(state) {
            return state.selects.filter((item) => item.field === 'titre');
        },
        origines_financement(state) {
            return state.selects.filter((item) => item.field === "origine_financement")
        },
        origines_emploi(state) {
            return state.selects.filter((item) => item.field === "origine_emploi")
        },
        attachments_types(state) {
            let attachments = state.selects.filter((item) => item.field === "attachments_types");
            attachments.sort((a, b) => {
                return a.value.toLowerCase().localeCompare(b.value.toLowerCase());
            })
            return attachments;
        },
        entreprise_attachments_types(state) {
            return state.selects.filter((item) => item.field === "entreprise_attachment");
        },
        statut_pret_types(state) {
            return state.selects.filter((item) => item.field === "statut_pret");
        },
        enqueteurs(state) {
            return state.selects.filter((item) => item.field === "enqueteurs");
        },
        controleurs_deal(state) {
            return state.selects.filter((item) => item.field === "controleurs_deal");
        },

        selects(state) {
            return state.selects;
        },
        fetch_status(state) {
            return state.fetch_status;
        }
    },
    actions: {
        async fetchSelects(context) {
            try {
                context.commit("setStatutsLoading");
                const selectRepository = new SelectRepository();
                let selects = await selectRepository.fetch();
                context.commit("setSelect", selects);
                context.commit("setStatutsLoaded");
            } catch (e) {
                context.commit("setStatutsError");
            }
        }
    }
}