import axios from "axios";

export const ContactStore = {
    namespaced: true,
    state: {

    },
    actions: {
        async patchContact({rootGetters, dispatch}, contact) {
            try {
                let response = await axios.patch(`/api/contacts/${contact.id}/`, contact, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postContact({rootGetters, dispatch}, contact) {
            try {
                let response = await axios.post(`/api/contacts/`, contact, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return null;
            }
        },
        async deleteContact({rootGetters, dispatch}, contact) {
            try {
                await axios.delete(`/api/contacts/${contact.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        }

    }
};