import {Api} from "@/repository/api";
import axios from "axios";
import store from "@/store";

export class CommentaireRepository extends Api {
    constructor() {
        super("commentaires");
    }
    async getByDossierId(dossier) {
        try {
            let response = await axios.get(`${this.url}/?dossier=${dossier.id}`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            console.error(e);
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }
    async getImportantMessages() {
        try {
            let response = await axios.get(`${this.url}/?important=true`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            console.error(e);
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }
}