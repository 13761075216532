import {CommentaireRepository} from "@/repository/commentaire";

export const CommentaireStore = {
    namespaced: true,
    state() {
        return {};
    },
    mutations: {},
    getters: {},
    actions: {
        async getByDossierId(context, dossier) {
            let repository = new CommentaireRepository();
            return await repository.getByDossierId(dossier);
        },
        async postCommentaire(context, commentaire) {
            let repository = new CommentaireRepository();
            return await repository.post(commentaire);
        },
        async patchCommentaire(context, commentaire) {
            let repository = new CommentaireRepository();
            return await repository.update(commentaire);
        },
        async deleteCommentaire(context, commentaire) {
            let repository = new CommentaireRepository();
            return await repository.delete(commentaire);
        }
    }
}