export const AnnonceStore = {
    namespaced: true,
    state() {
        return {
            hasMessage: false,
            annonce: null,
        }
    },
    mutations: {
        setAnnounce(state, annonce) {
            state.annonce = annonce;
        },
        setHasMessage(state, value) {
            state.hasMessage = value;
        }
    },
    getters: {
        getAnnounce(state) {
            return state.annonce;
        },
        getHasMessage(state) {
            return state.hasMessage;
        }
    },
    actions: {
        annonceError(context, message) {
            context.commit("setAnnounce", {type: "error", message});
            context.commit("setHasMessage", true);
        },
        annonceSuccess(context, message) {
            context.commit("setAnnounce", {type: "success", message});
            context.commit("setHasMessage", true);
        },
        annonceWarning(context, message) {
            context.commit("setAnnounce", {type: "warning", message});
            context.commit("setHasMessage", true);
        }
    }
}