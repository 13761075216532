import axios from "axios";

export const OperationStore = {
    namespaced: true,
    state: {

    },
    actions: {
        async fetchOperation({dispatch, rootGetters}) {
            try {
                let response = await axios.get("/api/operations/", rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async patchOperation({rootGetters, dispatch}, operation) {
            try {
                let response = await axios.patch(`/api/operations/${operation.id}/`, operation, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postOperation({rootGetters, dispatch}, operation) {
            try {
                let response = await axios.post(`/api/operations/`, operation, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        deleteOperation({rootGetters, dispatch}, operation) {
            try {
                axios.delete(`/api/operations/${operation.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                return "failure";
            }
        }

    }
};