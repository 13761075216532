<template>
  <v-app>
    <v-navigation-drawer
        v-model="drawer"
        app
        color="primary"
    >
      <NavigationBar/>
      <template v-slot:append>
        <v-list-item>
          <v-list-item-icon class="white--text">
            <v-icon color="white">mdi-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text font-weight-bold">Arcavs Web v1.3.1</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

    <v-app-bar style="max-width: 100vw" app color="primary">
      <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="white--text">ARCAVS</v-toolbar-title>

      <v-img contain max-height="70" src="@/assets/logo-arcavs.jpg"></v-img>
      <v-btn icon to="/profil" class="mt-4" v-if="loggedUser !== null">
        <v-icon color="white" class="mr-2">mdi-account</v-icon>
      </v-btn>
      <v-card color="primary" flat v-if="loggedUser !== null">
        <span class="white--text font-weight-bold">{{ loggedUser.first_name }} {{ loggedUser.last_name }}</span><br/>
        <a class="text-decoration-none white--text" @click="logout">Se déconnecter</a>
      </v-card>
    </v-app-bar>
    <v-dialog
        v-model="hasAlert"
        width="500"
        :color="alert.type"
    >

      <v-card>
        <v-toolbar :color="alert.type">
          <v-toolbar-title class="white--text">{{ alert.title }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <pre class="ma-2 mt-3 body-1 text-pre-wrap">{{ alert.message }}</pre>
          <div class="mt-2" v-if="alert.details !== null && alert.details !== undefined">
            <h4>Plus d'informations</h4>
            <ErrorList :value="alert.details"/>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-main style="background: #f7f5fc">
      <router-view :key="$route.fullPath"></router-view>
      <v-snackbar v-if="annonce" v-model="hasMessage" :timeout="3000" :color="annonce.type">
        {{ annonce.message }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from "@/components/home/NavigationBar";
import ErrorList from "@/components/base/ErrorList";

export default {
  name: "App",
  components: {NavigationBar, ErrorList},
  data() {
    return {drawer: null};
  },
  computed: {
    hasMessage: {
      get() {
        return this.$store.getters["annonce/getHasMessage"];
      },
      set(value) {
        return this.$store.commit("annonce/setHasMessage", value);
      }
    },
    annonce() {
      return this.$store.getters["annonce/getAnnounce"];
    },
    hasAlert: {
      get() {
        return this.$store.getters["alerts/hasMessage"];
      },
      set(value) {
        return this.$store.commit("alerts/changeVisibility", value)
      }
    },
    alert() {
      return this.$store.getters["alerts/announce"];
    },
    loggedUser() {
      return this.$store.getters["auth/getLoggedUser"];
    },
  },
  methods: {

    logout() {
      this.$router.push("/login");
      return this.$store.dispatch("auth/logout");
    }
  }
}
</script>