<template>
  <div>
    <v-list-item v-if="link.children === undefined || link.children.length === 0" :to="link.to !== null ? link.to : null" @click="link.action !== null ? link.action() : null">
      <v-list-item-icon class="white--text">
        <v-icon color="white">{{ link.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="white--text">{{ link.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group
        v-else
        color="white"
        no-action
      >
        <template v-slot:prependIcon>
          <v-icon color="white">{{ link.icon }}</v-icon>
        </template>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="white--text" v-text="link.name"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in link.children"
          :key="child.name"
          class="white--text"
          :to="child.to !== null ? child.to : null" @click="child.action !== null ? child.action() : null"
        >
          <v-list-item-content>
            <v-list-item-title class="white--text" v-text="child.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
  </div>
</template>
<script>
import {AuthMixin} from "@/mixins/AuthMixin";

export default {
  name: 'NavigationLink',
  mixins:[AuthMixin],
  props: {
    link: Object
  }
}
</script>