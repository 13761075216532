import {EntrepriseRepository} from "@/repository/entreprises";


export const EntrepriseStore = {
    namespaced: true,
    state() {
        return {
            entreprises: [],
            fetch_status: "INITIAL",
        };
    },
    mutations: {
        setEntreprises(state, entreprises) {
            state.entreprises = entreprises;
        },
        setStatutsLoading(state) {
            state.fetch_status = "LOADING";
        },
        setStatutsLoaded(state) {
            state.fetch_status = "LOADED";
        },
        setStatutsError(state) {
            state.fetch_status = "ERROR";
        },
    },
    getters: {
        entreprises(state) {
            return state.entreprises;
        },
        fetch_status(state) {
            return state.fetch_status;
        }
    },
    actions: {
        async fetchEntreprises(context) {
            try {
                context.commit("setStatutsLoading");
                const entrepriseRepository = new EntrepriseRepository();
                let entreprises = await entrepriseRepository.fetch();
                context.commit("setEntreprises", entreprises);
                context.commit("setStatutsLoaded");
            } catch (e) {
                context.commit("setStatutsError");
            }
        }
    }
}