import axios from "axios";

export const CategoryStore = {
    namespaced: true,
    state: {
        categories: [],
        status: "INITIAL",
    },
    mutations: {
        setCategories(state, categories) {
            state.categories = categories;
        },
        addCategory(state, category) {
            state.categories.push(category);
            state.categories.sort((a, b) =>  a.id - b.id);
        },
        updateCategory(state, category) {
          state.categories.map((r) => category.id === r.id ? category : r);
        },
        deleteCategory(state, category) {
          state.categories.filter((r) => category.id !== r.id);
        },
        setStatus(state, status) {
            state.status = status;
        }
    },
    getters: {
        categories(state) {
            return state.categories;
        },
        loading_status(state) {
            return state.status;
        }
    },
    actions: {
        async fetchCategory({dispatch, commit, rootGetters}) {
            commit("setStatus", "LOADING");
            try {
                let response = await axios.get("/api/categories/", rootGetters["auth/getAuth"]);
                commit("setCategories", response.data);
                commit("setStatus", "LOADED");
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
                commit("setStatus", "ERROR");
            }
        },
        async patchCategory({rootGetters, commit, dispatch}, category) {
            try {
                let response = await axios.patch(`/api/categories/${category.id}/`, category, rootGetters["auth/getAuth"]);
                commit("updateCategory", response.data);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
       async postCategory({rootGetters, commit, dispatch}, category) {
            try {
                let response = await axios.post(`/api/categories/`, category, rootGetters["auth/getAuth"]);
                commit("addCategory", response.data);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        deleteCategory({rootGetters, commit, dispatch}, category) {
            try {
                axios.delete(`/api/categories/${category.id}/`,  rootGetters["auth/getAuth"]);
                commit("deleteCategory", category);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        }

    }
};