const routes = [
    {name: "Tableau de bord", intern:false, icon: "mdi-view-dashboard", to: "/", action: null},
    {name: "Dossiers", intern: false, icon: "mdi-folder", to: "/dossier", action: null},
    {name: "Entreprises",intern: true, icon: "mdi-office-building", to: "/entreprises", action: null},
    {name: "Paramètres",intern: true, icon: "mdi-cog", to: "/admin", action: null},
    {
        name: "Finances", intern: true, icon: "mdi-currency-eur", to: "/finances", action: null, children: [
            {name: "Général", icon: "mdi-cog", to: "/finances", action: null},
            {name: "Factures en attente", icon: "mdi-cog", to: "/factures/waiting", action: null},
            {name: "Factures à payer", icon: "mdi-cog", to: "/factures/valid", action: null},
            {name: "Virements à vérifier", icon: "mdi-cog", to: "/factures/check", action: null},
            {name: "Factures payées", icon: "mdi-cog", to: "/factures/paid", action: null},
            {name: "Suivi des financements", icon: "mdi-cog", to: "/financements", action: null},
            {name: "Suivi de la facturation", icon: "mdi-cog", to: "/facturation", action: null},
        ]
    },
    {name: "Statistiques", intern: true, icon: "mdi-view-dashboard", to: "/stats", action: null},
]

function getLinks() {
    return routes;
}

export {getLinks};