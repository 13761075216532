import {Api} from "@/repository/api";
import axios from "axios";
import store from "@/store";

/**
 * @augments IApi<Entreprise>
 * @extends IApi<Entreprise>
 * @implements IApi<Entreprise>
 */
export class EntrepriseRepository extends Api {
    constructor() {
        super("entreprises");
    }

}

export class EntrepriseAttachmentRepository extends Api {
    constructor() {
        super("entreprise_attachments");
    }

    async postFile(object) {
        let data = new FormData();
        for (const key in object) {
            data.append(key, object[key]);
        }
        try {
            let response = await axios.post(`${this.url}/`, data, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            store.dispatch("annonce/annonceError", "Une erreur est survenue");
        }
    }
}