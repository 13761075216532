import {AUTHENTIFICATION_STATUS} from "@/constants/AuthentificationStatus";

export const AuthMixin = {
    computed: {
        logged_user() {
            return this.$store.getters["auth/getLoggedUser"];
        },
        isAdmin() {
            return this.$store.getters["auth/getLoggedUser"].group === "Administrateur";
        },
        current_auth_status() {
            return this.$store.getters["auth/getStatus"];
        },
        auth_status() {
            return AUTHENTIFICATION_STATUS;
        },
        hasFinanceRole() {
            return ["Administrateur", "Gestionnaire"].includes(this.$store.getters["auth/getLoggedUser"].group);
        },
        isIntern() {
            return this.logged_user === null ? false : ["Administrateur", "Gestionnaire", "Instructeur"].includes(this.logged_user.group);
        },
        isMaitreOeuvre() {
            return this.$store.getters["auth/getLoggedUser"].group === "Maître d'oeuvre";
        },
        isConducteurDeTravaux() {
            return this.$store.getters["auth/getLoggedUser"].group === "Conducteur de travaux";
        },

    }
}