import axios from "axios";
import * as config from "@/config.json";

export const DevisStore = {
    namespaced: true,
    state: {

    },
    actions: {
        async getDevisById({dispatch, rootGetters}, devis) {
            try {
                let response = await axios.get(`/api/devis/${devis.id}`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async patchDevis({rootGetters, dispatch}, devis) {
            try {
                let response = await axios.patch(`/api/devis/${devis.id}/`, devis, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async generateDevis({rootGetters, dispatch}, devis) {
            try {
                let response = await axios.post(`/api/devis/${devis.id}/generate/`, devis, rootGetters["auth/getAuth"]);
                let url = config.BASE_URL + response.data["url"];
                const link = document.createElement('a');
                link.target = "_blank";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.remove();
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async generateBon({rootGetters, dispatch}, commande) {
            try {
                let response = await axios.get(`/api/commandes/${commande.id}/generate/`, rootGetters["auth/getAuth"]);
                let url = config.BASE_URL + response.data["url"];
                const link = document.createElement('a');
                link.target = "_blank";
                link.href = url;
                document.body.appendChild(link);
                link.click();
                link.remove();
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async patchDevisFile({dispatch, rootGetters}, devis) {
            let formData = new FormData();
            formData.append("id", devis.id);
            formData.append("proposal_file", devis.proposal_file);
            let axiosConfig = rootGetters["auth/getAuth"];
            let response = await axios.patch(`/api/devis/${devis.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                ...axiosConfig
            });
            if (response.status === 201 || response.status === 200) {
                return response.data;
            } else {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async postDevis({rootGetters, dispatch}, devis) {
            try {
                let response = await axios.post(`/api/devis/`, devis, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        async duplicate({rootGetters, dispatch}, devis) {
            try {
                let response = await axios.post(`/api/devis/${devis.id}/duplicate/`,{}, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                console.log(e);
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
            }
        },
        deleteDevis({rootGetters, dispatch}, devis) {
            try {
                axios.delete(`/api/devis/${devis.id}/`,  rootGetters["auth/getAuth"]);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root:true});
                return "failure";
            }
        }

    }
};