import Vue from 'vue'
import Vuex from 'vuex'
import {AuthStore} from "@/store/auth";
import {AnnonceStore} from "@/store/annonce";
import {DossierStore} from "@/store/dossier";
import {BeneficiaireStore} from "@/store/beneficiaire";
import {BienImmobilierStore} from "@/store/bien_immobilier";
import {ReferentielStore} from "@/store/referentiel";
import {CategoryStore} from "@/store/category";
import {FinancementStore} from "@/store/financement";
import {ModelDocStore} from "@/store/modeldoc";
import {DocumentStore} from "@/store/documents";
import {HonoraireStore} from "@/store/honoraires";
import {AttachmentStore} from "@/store/attachment";
import {UserStore} from "@/store/users";
import {GroupsStore} from "@/store/groups";
import {ContactStore} from "@/store/contacts";
import {DevisStore} from "@/store/devis";
import {OperationStore} from "@/store/operations";
import {SelectStore} from "@/store/select";
import {ConfigStore} from "@/store/config";
import {alertStore} from "@/store/alert";
import {PasswordResetStore} from "@/store/passwordreset";
import {EntrepriseStore} from "@/store/entreprises";
import {ClientStore} from "@/store/clients";
import {StatsStore} from "@/store/stats";

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    auth: AuthStore,
    annonce: AnnonceStore,
    dossier: DossierStore,
    beneficiaire: BeneficiaireStore,
    bien_immobilier: BienImmobilierStore,
    referentiel: ReferentielStore,
    category: CategoryStore,
    financement: FinancementStore,
    honoraire: HonoraireStore,
    modeldocs: ModelDocStore,
    documents: DocumentStore,
    attachments: AttachmentStore,
    users: UserStore,
    groups: GroupsStore,
    contact: ContactStore,
    devis: DevisStore,
    operations: OperationStore,
    select: SelectStore,
    config: ConfigStore,
    alerts: alertStore,
    passwordreset: PasswordResetStore,
    entreprises: EntrepriseStore,
    clients: ClientStore,
    stats: StatsStore,
  }
})
export default store;
