import axios from "axios";

export const ReferentielStore = {
    namespaced: true,
    state: {
        referentiel: [],
        status: "INITIAL",
    },
    mutations: {
        setReferentiel(state, referentiel) {
            state.referentiel = referentiel;
        },
        addReferentiel(state, referentiel_item) {
            state.referentiel.push(referentiel_item);
            state.referentiel = state.referentiel.sort((a, b) =>  a.id - b.id);
        },
        setStatus(state, status) {
          state.status = status;
        },
        updateReferentiel(state, referentiel_item) {
          state.referentiel = state.referentiel.map((r) => referentiel_item.id === r.id ? referentiel_item : r);
        },
        deleteReferentiel(state, referentiel_item) {
          state.referentiel = state.referentiel.filter((r) => referentiel_item.id !== r.id);
        },
    },
    getters: {
        referentiel(state) {
            return state.referentiel;
        },
        loading_status(state) {
            return state.status;
        }
    },
    actions: {
        async fetchReferentiel({commit, dispatch, rootGetters}) {
            try {
                let response = await axios.get("/api/referentiel/", rootGetters["auth/getAuth"]);
                commit("setReferentiel", response.data);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async patchReferentiel({commit, rootGetters, dispatch}, referentiel) {
            try {
                let response = await axios.patch(`/api/referentiel/${referentiel.id}/`, referentiel, rootGetters["auth/getAuth"]);
                commit("updateReferentiel", response.data);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async postReferentiel({commit, rootGetters, dispatch}, referentiel) {
            commit("setStatus", "LOADING");
            try {
                let response = await axios.post(`/api/referentiel/`, referentiel, rootGetters["auth/getAuth"]);
                commit("addReferentiel", response.data);
                commit("setStatus", "LOADED");
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root:true});
                commit("setStatus", "ERROR");
            }
        },
        async deleteReferentiel({commit, rootGetters, dispatch}, referentiel) {
            try {
                await axios.delete(`/api/referentiel/${referentiel.id}/`, rootGetters["auth/getAuth"]);
                commit("deleteReferentiel", referentiel);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
                return "failure";
            }
        }

    }
};